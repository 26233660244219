@use "../../assets/fonts";

.app__header {
    display: flex;
    position: relative;
    overflow: hidden;
    height: 100%;
    left: -3vw;
}

.app__header > * {
    width: 100%;
    height: 80vh;
    object-fit: contain;
    overflow: hidden;
}

.app__header-logo_text {
    width: 42%;
    height: fit-content;
    min-height: 4rem;
    position: absolute;
    display: flex;
    z-index: 60;
    right: 0;
    bottom: 0;
    margin-bottom: 5rem;
    margin-right: 1rem;
    background-color: white;
    border-radius: 1rem;
    transform: rotate(-5deg);
    overflow: hidden;
    justify-content: center;
    animation: slide-in-text;
    animation-duration: 1.8s;

    img {
        margin: -2rem;
        width: 100%;
        object-fit: scale-down;
    }

    @media screen and (max-width: 1200px) {
        width: 76%;
        margin-right: 2rem;
        margin-bottom: 8rem;
    }
    
    @media screen and (max-width: 900px) {
        margin-bottom: 13rem;
    }

    @media screen and (min-width: 2000px) {
        margin-bottom: 9rem;
    }
}


.app__header-logo_sub {
    width: 18vw;
    height: fit-content;
    position: absolute;
    display: flex;
    right: 0;
    bottom: 0;
    justify-content: center;
    margin-bottom: 1rem;
    z-index: 55;
    margin-right: 4rem;
    animation: slide-in-text-sub;
    animation-duration: 2.4s;

    img {
        transform: rotate(-3deg);
    }

    @media screen and (max-width: 1200px) {
        width: 32vw;
        margin-right: -0rem;
        margin-bottom: 3rem;
        background-color: rgba(#edf2f8, 0.8);
        border-radius: 1rem;
    }

    @media screen and (max-width: 900px) {
        width: 35vw;
        margin-right: 4rem;
        margin-bottom: 8rem;
    }

    @media screen and (max-width: 768px) {
        width: 35vw;
        margin-right: 4rem;
        margin-bottom: 11rem;
    }


    @media screen and (min-width: 2000px) {
        width: 20vw;
    }
}

.app__header-mouse {
    position: absolute;
    width: 6%;
    height: 6%;
    right: 41vw;
    top: 89vh;
    z-index: 90;
    display: flex;
    justify-content: center;

    svg {
        width: 100%;
        height: 100%;
        color: rgba(#edf2f8, 1);
    }

    @media screen and (max-width: 1100px) {
        display: none;
    }
}

.app__header-mouse-down {
    position: absolute;
    width: 6%;
    height: 6%;
    right: 41vw;
    top: 95vh;
    z-index: 90;
    display: flex;
    justify-content: center;

    svg {
        width: 50%;
        height: 50%;
        color: rgba(#edf2f8, 0.8);
    }
    
    @media screen and (max-width: 1100px) {
        display: none;
    }
}

.dom {
    position: absolute;
    z-index: 10;
    height: 98vh;
    right: 26vw;
    top: -10vh;
    @media screen and (max-width: 900px) {
        top: -20vh;
    }
}

.stephen {
    position: absolute;
    z-index: 30;
    top: 10vh;
    height: 90vh;
    right: 10vw;
    @media screen and (max-width: 900px) {
        top: -1vh;
    }
}

.becca {
    position: absolute;
    right: -1vw;
    z-index: 50;
    top: 20vh;
    @media screen and (max-width: 900px) {
        top: 12vh;
    }
}

.rebecca {
    position: absolute;
    z-index: 30;
    top: 20vh;
    right: -8vw;
    height: 64vh;
    max-width: 81%;
    @media screen and (max-width: 900px) {
        top: 9vh;
    }
}

.emily {
    position: absolute;
    z-index: 10;
    right: -30vw;
    height: 85vh;
    top: -4vh;
    overflow: hidden;
    @media screen and (max-width: 900px) {
        top: -15vh;
    }
}

.app__header-tickets {
    z-index: 55;
    position: absolute;
    display: flex;
    left: 8vw;
    top: 55vh;
    width: 25vw;
    height: 55vh;
    transform: rotate(-5deg);
    animation: slide-in-ticket;
    animation-duration: 1.5s;
    cursor: pointer;

    @media screen and (max-width: 1200px) {
        width: 22vw;
        height: 35vh;
        top: 30vh;
        left: -1vw;
        transform: rotate(-1deg);
    }

    @media screen and (max-width: 780px) {
        display: none;
    }
}

.app__header-tickets:hover {
    transform: rotate(-4deg) translateY(-3vh);
}

.app__header-tickets-wide {
    display: none;

    @media screen and (max-width: 780px) {
        z-index: 60;
        display: flex;
        position: absolute;
        width: 80vw;
        height: 30vh;
        top: 80vh;
        right: 4vw;
        transform: rotate(12deg);
        animation: slide-in-wide;
        animation-duration: 2s;
    }
}


@keyframes slide-in-ticket {
    0% {
        -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
                transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
        -webkit-transform-origin: 50% 100%;
                transform-origin: 50% 100%;
        opacity: 0;
    }
    60% {
        -webkit-transform: translateY(1000px) scaleY(1) scaleX(1) rotate(-40deg);
                transform: translateY(1000px) scaleY(1) scaleX(1) rotate(-40deg);
        -webkit-transform-origin: 50% 100%;
                transform-origin: 50% 100%;
        opacity: 0;
    }
    80% {
        -webkit-transform: translateY(-80px) translateX(25px) scaleY(1.1) scaleX(1) rotate(-10deg);
                transform: translateY(-80px) translateX(25px) scaleY(1.1) scaleX(1) rotate(-10deg);
        opacity: 1;
    }
    90% {
        -webkit-transform: translateY(80px) translateX(5px) scaleY(0.8) scaleX(1.1) rotate(-3deg);
                transform: translateY(80px) translateX(5px) scaleY(0.8) scaleX(1.1) rotate(-3deg);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(0) scaleY(1) scaleX(1) rotate(-5deg);
                transform: translateY(0) scaleY(1) scaleX(1) rotate(-5deg);
        opacity: 1;
    }
}

@keyframes slide-in-text {
    0% {
        -webkit-transform: translateY(1000px) scaleY(1) scaleX(1);
                transform: translateY(1000px) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 100%;
                transform-origin: 50% 100%;
        opacity: 0;
    }
    60% {
        -webkit-transform: translateY(1000px) scaleY(1) scaleX(1) rotate(-5deg);
                transform: translateY(1000px) scaleY(1) scaleX(1) rotate(-5deg);
        -webkit-transform-origin: 50% 100%;
                transform-origin: 50% 100%;
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0) scaleY(1) scaleX(1) rotate(-5deg);
                transform: translateY(0) scaleY(1) scaleX(1) rotate(-5deg);
        opacity: 1;
    }
}

@keyframes slide-in-text-sub {
    0% {
        -webkit-transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
                transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
        -webkit-transform-origin: 50% 100%;
                transform-origin: 50% 100%;
        opacity: 0;
    }
    60% {
        -webkit-transform: translateY(1000px) scaleY(1) scaleX(1);
                transform: translateY(1000px) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 100%;
                transform-origin: 50% 100%;
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0) scaleY(1) scaleX(1);
                transform: translateY(0) scaleY(1) scaleX(1);
        opacity: 1;
    }
}

@keyframes slide-in-wide {
    0% {
        -webkit-transform: translateY(1000px) scaleY(1) scaleX(1);
                transform: translateY(1000px) scaleY(1) scaleX(1);
        -webkit-transform-origin: 50% 100%;
                transform-origin: 50% 100%;
        opacity: 0;
    }
    60% {
        -webkit-transform: translateY(200px) scaleY(1) scaleX(1) rotate(-5deg);
                transform: translateY(200px) scaleY(1) scaleX(1) rotate(-5deg);
        -webkit-transform-origin: 50% 100%;
                transform-origin: 50% 100%;
        opacity: 0;
    }

    100% {
        -webkit-transform: translateY(0) scaleY(1) scaleX(1) rotate(12deg);
                transform: translateY(0) scaleY(1) scaleX(1) rotate(12deg);
        opacity: 1;
    }
}

@keyframes fadeInImages {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}